<template>
  <div class="container">
    <div class="x-van-group">
      <van-field
        readonly
        :value="form.title"
        label="诉求标题"
        label-width="4.2em"
      />
      <van-field
        readonly
        label="诉求描述"
        label-width="4.2em"
      >
        <template #input>
          <span>{{form.description}}</span>
        </template>
      </van-field>
      <van-field
        readonly
        :value="form.branchName"
        label="小区名称"
        label-width="4.2em"
      />
      <van-field
        readonly
        :value="form.contact"
        label="联系方式"
        label-width="4.2em"
      />

      <van-field
        readonly
        :value="form.createTime"
        label="上报时间"
        label-width="4.2em"
      />
      <van-field name="imgList" label-width="4.2em" label="图片">
        <template #input>
          <div v-if="imgList.length == 0">无</div>
          <div v-else>
            <van-image
              v-for="(item, index) in imgList"
              :key="index"
              width="80"
              height="80"
              fit="contain"
              :src="item"
              @click="imagePreviewAppeal(index)"
            />
          </div>
        </template>
      </van-field>
    </div>
    <div class="x-van-group" v-if="(form.isHandle === 1)">
      <van-field
        readonly
        :value="form.updateTime"
        label="处理时间"
        label-width="4.2em"
      />
      <van-field
        readonly
        :value="form.isHandle === 1?'已处理':'待处理'"
        label="处理状态"
        label-width="4.2em"
      />
      <van-field
        readonly
        label="处理回复"
        label-width="4.2em"
      >
        <template #input>
          <span>{{form.yiJian}}</span>
        </template>
      </van-field>
      <van-field label-width="4.2em" label="处理图片">
        <template #input>
          <div v-if="imgList2.length == 0">无</div>
          <div v-else>
            <van-image
              v-for="(item, index) in imgList2"
              :key="index"
              width="80"
              height="80"
              fit="contain"
              :src="item"
              style="margin:0 5px"
              @click="imagePreviewDeal(index)"
            />
          </div>
        </template>
      </van-field>
      <!-- <van-field 
        label="发表评价" 
        label-width="4.2em" 
        readonly  
        v-if="(form.feedback!=0)"
      >
        <template #input>
          <div v-for="(item,index) in evaluateList" :key="index" style="margin:0 5px;float:left;">
            <van-tag type="primary" :text-color="form.feedback==item.key?'#fff':'#7B7B7B'" :color="form.feedback==item.key?'#2EB1FC':'#F2F2F2'">{{ item.value }}</van-tag>
          </div>
        </template>
      </van-field> -->
      

      <van-field 
        label="发表评价" 
        label-width="4.2em" 
        readonly
      >
        <template #input>
          <!-- <div v-for="(item,index) in evaluateList" :key="index" style="margin:0 5px;float:left;">
            <van-tag type="primary" :text-color="gradeForm.feedback==item.key?'#fff':'#7B7B7B'" :color="gradeForm.feedback==item.key?'#2EB1FC':'#F2F2F2'" @click="toggle(item.key)">{{ item.value }}</van-tag>
          </div> -->

          <div class="evaluate-box">
            <div class="item" :class="{'act': gradeForm.feedback == item.key}" v-for="(item,index) in evaluateList" :key="index" @click="toggle(item.key)">{{ item.value }}</div>
          </div>
        </template>
      </van-field>

      <van-field
        v-if="(form.feedback!=0)"
        readonly
        label="评价描述"
        label-width="4.2em"
      >
        <template #input>
          <span>{{form.feedbackRemake}}</span>
        </template>
      </van-field>

      <van-field
        v-else
        v-model="gradeForm.feedbackRemake"
        label="评价描述"
        label-width="4.2em"
        type="textarea"
        maxlength="50"
        :show-word-limit=true
        placeholder="请对本次诉求处理进行评价"
      />
    </div>
    <div class="sub" v-if="(form.feedback==0 && form.isHandle === 1)">
      <van-button block type="info" @click="raiseSubmit">提交</van-button>
    </div>
  </div>
</template>
<script>
import { getDetail, feedBack} from '@/api/eventApi';
import { ImagePreview } from 'vant';
export default {
  name: 'banner',
  data() {
    return {
      eventType:4,
        form: {
          
        },
        gradeForm: {
          feedback:1,
          id: '',
        },
        imgList: [],
        evaluateList: [
          {
            key: 1,
            value: '非常满意',
          },
          {
            key: 2,
            value: '满意',
          },
          {
            key: 3,
            value: '一般',
          },{
            key: 4,
            value: '不满意',
          }
        ]

    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.getInfo(this.$route.query.id)
  },
  mounted() {},
  methods: {
    imagePreviewAppeal(index){
      ImagePreview({
        images:this.imgList,
        startPosition: index,
      });
    },
    imagePreviewDeal(index){
      ImagePreview({
        images:this.imgList2,
        startPosition: index,
      });
    },
    toggle(key) {
      if(this.form.feedback==0) {
        this.gradeForm.feedback = key
      }
    },
    raiseSubmit(){
      this.gradeForm.id = this.form.id
      this.$store.commit('SET_IS_LOADING', true)
      feedBack(this.gradeForm).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        if(res.code == 200) {
          this.$toast({
            type: 'success',
            message: '感谢您的评价,您的满意是我前进的动力',
            duration: 2000,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        } else {
          this.$toast({
            type: 'error',
            message: res.msg,
            duration: 1000,
          })
        }
      })
    },
    getInfo(id) {
      this.$store.commit('SET_IS_LOADING', true)
      getDetail({
        id: id
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.form = res.data
        this.imgList = res.data.picUrl?res.data.picUrl.split(','):[]
        this.imgList2 = res.data.handlePicUrl?res.data.handlePicUrl.split(','):[]
      })
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 5px;
  font-size: 14px;
}
.van-cell::after{
  border-bottom: none;
}
.x-van-group{
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #E4E4E4;
}
.x-van-group:last-child{
  border:none;
}
.van-image{
  margin:0 5px;
}
.sub {
  padding: 0 15px;
  margin-top: 12px;
  box-sizing: border-box;
}
</style>
